export const defaultCancellationFeeObject = () => ({
  code: null,
  name: null,
  fee_type: "basic",
  fees: {
    no_show: null,
    same_day: null,
    days_before: []
  },
  max_fee: null,
  description: null
})
